import { Box, Typography } from "@mui/material"
import Settings from "../account/settings"

export const SuccessPage = () => {

    return (
        <>
            <Box pb={2} mt={-2}>
                <Typography variant="h5">Welcome to imagetocaption.ai🥳</Typography>
            </Box>
            <Box mb={4}>
                <iframe
                    src="https://v2-embednotion.com/Getting-started-d0fd7f69d0f14cc68ee3191f873fcc47?pvs=74"
                    style={{
                        width: '100%',
                        height: '500px',
                        border: '0px solid #ccc',
                        borderRadius: '10px',
                        padding: 'none'
                    }}
                    title="Knowledgebase"
                />
            </Box>
            <Settings />

        </>
    )
}
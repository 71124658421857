import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import { Contact } from './contact';
import { Button, Divider, IconButton, Modal, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { MusicNote } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Faq } from './tool/components/pricingPlans/faq';
import { ImageContext } from '../context/ImageContext';
import logo from './images/imagetocaptionai.svg';

export function Footer() {
  const { plan } = useContext(ImageContext);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    setOpen(false);
  }

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
      <Stack direction="column" width={"100%"}>
        <Box mt={4}>
          <img style={{ width: "200px" }} src={logo} alt="imagetocaption logo"></img>
          <Typography variant="body2" component="p">
            ©2024. imagetocaption.ai is a product by imagetocaption GmbH
          </Typography>
        </Box>
        <Box pt={1} pb={3}>
          <Divider />
        </Box>
        <Box pt={2}>
          {isMobile ? (
            <>
              <Stack direction="row" spacing={2} justifyContent={"center"}>
                <a href="https://www.instagram.com/imagetocaptionai/" target='_blank' rel="noreferrer" title="Instagram">
                  <IconButton
                    aria-label="InstagramButton"
                    color='primary'
                  >
                    <InstagramIcon />
                  </IconButton>
                </a>
                <a href="https://www.tiktok.com/@imagetocaptionai" target='_blank' rel="noreferrer" title="TikTok">
                  <IconButton
                    aria-label="TikTok Button"
                    color='primary'
                    title="TikTok"
                  >
                    <MusicNote />
                  </IconButton>
                </a>
                <a href="https://www.facebook.com/people/Imagetocaptionai/100094291297207/" target='_blank' rel="noreferrer" title="Facebook">
                  <IconButton
                    aria-label="FacebookButton"
                    color='primary'
                  >
                    <FacebookIcon />
                  </IconButton>
                </a>
                <a href="https://twitter.com/imagetocaption" target='_blank' rel="noreferrer" title="X">
                  <IconButton
                    aria-label="TwitterButton"
                    color='primary'
                  >
                    <TwitterIcon />
                  </IconButton>
                </a>
                <a href="https://www.linkedin.com/company/imagetocaption-ai" target='_blank' rel="noreferrer" title="LinkedIn">
                  <IconButton
                    aria-label="LinkedInButton"
                    color='primary'
                  >
                    <LinkedInIcon />
                  </IconButton>
                </a>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent={"center"}>
                <Button onClick={() => setOpen(true)} >Contact</Button>
                <Button onClick={() => navigate('/privacy-policy')} >Privacy Policy</Button>
                <Button onClick={() => navigate('/imprint')} >Legal Info</Button>
                <Button onClick={() => navigate('/terms-and-conditions')} >Terms and Conditions</Button>
              </Stack>
            </>
          ) : (
            <Stack direction="row" spacing={2} justifyContent={"center"}>
              <a href="https://www.instagram.com/imagetocaptionai/" target='_blank' rel="noreferrer" title="Instagram">
                <IconButton
                  aria-label="InstagramButton"
                  color='primary'
                >
                  <InstagramIcon />
                </IconButton>
              </a>
              <a href="https://www.tiktok.com/@imagetocaptionai" target='_blank' rel="noreferrer" title="TikTok">
                <IconButton
                  aria-label="TikTok Button"
                  color='primary'
                  title="TikTok"
                >
                  <MusicNote />
                </IconButton>
              </a>
              <a href="https://www.facebook.com/people/Imagetocaptionai/100094291297207/" target='_blank' rel="noreferrer" title="Facebook">
                <IconButton
                  aria-label="FacebookButton"
                  color='primary'
                >
                  <FacebookIcon />
                </IconButton>
              </a>
              <a href="https://twitter.com/imagetocaption" target='_blank' rel="noreferrer" title="X">
                <IconButton
                  aria-label="TwitterButton"
                  color='primary'
                >
                  <TwitterIcon />
                </IconButton>
              </a>
              <a href="https://www.linkedin.com/company/imagetocaption-ai" target='_blank' rel="noreferrer" title="LinkedIn">
                <IconButton
                  aria-label="LinkedInButton"
                  color='primary'
                >
                  <LinkedInIcon />
                </IconButton>
              </a>
              <Button onClick={() => setOpen(true)} >Contact</Button>
              <Button onClick={() => navigate('/privacy-policy')} >Privacy Policy</Button>
              <Button onClick={() => navigate('/imprint')} >Legal Info</Button>
              <Button onClick={() => navigate('/terms-and-conditions')} >Terms and Conditions</Button>
            </Stack>
          )}
        </Box>
      </Stack>


      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Contact />
        </Box>
      </Modal>
    </Box>
  );
}
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"

export const ParameterCheckbox = ({ paramsDisabled, label, value, handleCheckboxChange }) => {

    return (
        <FormControl component="fieldset" disabled={paramsDisabled}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup row name={label} value={value} onChange={handleCheckboxChange} sx={{ justifyContent: 'center' }}>
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
        </FormControl>
    )
}
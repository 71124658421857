import { InfoOutlined } from "@mui/icons-material"
import { Stack, FormControl, Autocomplete, TextField, Tooltip, Box, IconButton } from "@mui/material"
import { useState } from "react";

export const ParameterField = ({ options, value, handleParamChange, label, title }) => {

    const [paramsDisabled, setParamsDisabled] = useState(false);

    const [tooltipOpen, setTooltipopen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipopen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipopen(true);
    };

    const handleClick = () => {
        setTooltipopen(!tooltipOpen);
    };

    return (
        <Stack direction="row">
            <FormControl sx={{ pb: 4, width: 250 }}>
                <Autocomplete
                    freeSolo
                    options={options}
                    value={value}
                    onChange={(event, newValue) => { handleParamChange(newValue) }}
                    onInputChange={(event, newInputValue) => { handleParamChange(newInputValue) }}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    disabled={paramsDisabled}
                />
            </FormControl>
            <Tooltip
                arrow
                title={title}
                open={tooltipOpen}
                onMouseEnter={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -45],
                                },
                            },
                        ],
                    },
                }}
            >
                <Box pt={1.3}>
                    <IconButton sx={{ pt: 0.6 }} onClick={handleClick}>
                        <InfoOutlined />
                    </IconButton>
                </Box>
            </Tooltip>
        </Stack>
    )
}
import React, { useContext, useEffect } from 'react';
import { FormControl, MenuItem, Select, InputLabel, Box, Stack, Typography, Divider, Button } from '@mui/material';
import { ImageContext, ImageDispatchContext } from '../../../context/ImageContext';
import { ParameterField } from './parameterField';
import { ParameterCheckbox } from './parameterCheckbox';
import { ParameterSlider } from './parameterSlider';
import { useNavigate } from 'react-router-dom';

const CaptionOptions = () => {
    const { language, brandvoice, brandvoiceExist, theme, setting, mood, custom, emojis, hashtags, cta, ctaType, preset, length, plan, numberofhashtags, creditsCost } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);

    const [maxLength, setMaxLength] = React.useState(100);
    const [maxHashtagLength, setMaxHashtagLength] = React.useState(30)

    const handlePresetChange = (event: { target: { value: any; }; }) => {
        dispatch({ type: 'CHANGE_PRESET', preset: event.target.value });
        if (event.target.value === "long") {
            dispatch({ type: "CHANGE_CREDITS_COST", creditsCost: creditsCost + 1 })
        } else {
            dispatch({ type: "CHANGE_CREDITS_COST", creditsCost: creditsCost - 1 })
        }
    }

    const handleLanguageChange = (event: { target: { value: any; }; }) => {
        dispatch({ type: 'CHANGE_LANGUAGE', language: event.target.value });
    };

    const handleBrandvoiceChange = (event: { target: { value: any; }; }) => {
        dispatch({ type: 'CHANGE_BRANDVOICE', brandvoice: event.target.value });
    };

    const handleThemeChange = (value: string) => {
        dispatch({ type: 'CHANGE_THEME', theme: value });
    };

    const handleSettingChange = (value: string) => {
        dispatch({ type: 'CHANGE_SETTING', setting: value });
    };

    const handleMoodChange = (value: string) => {
        dispatch({ type: 'CHANGE_MOOD', mood: value });
    };

    const handleCustomChange = (value: string) => {
        dispatch({ type: 'CHANGE_CUSTOM', custom: value });
    };

    const handleEmojisChange = (event: React.ChangeEvent<{}>, value: string) => {
        let newValue = null;
        value === "true" ? newValue = true : newValue = false;
        dispatch({ type: 'CHANGE_EMOJIS', emojis: newValue });
    };

    const handleHashtagsChange = (event: React.ChangeEvent<{}>, value: string) => {
        let newValue = null;
        value === "true" ? newValue = true : newValue = false;
        dispatch({ type: 'CHANGE_HASHTAGS', hashtags: newValue });
    };

    const handleCtaChange = (event: React.ChangeEvent<{}>, value: string) => {
        let newValue = null;
        value === "true" ? newValue = true : newValue = false;
        dispatch({ type: 'CHANGE_CTA', cta: newValue });
    };

    const handleCtaTypeChange = (value: string) => {
        dispatch({ type: 'CHANGE_CTATYPE', ctaType: value })
    }

    const handleLengthChange = (event: React.ChangeEvent<{}>, newValue) => {
        dispatch({ type: 'CHANGE_LENGTH', length: newValue });
    };

    const handleHashtagsLengthChange = (event: React.ChangeEvent<{}>, newValue) => {
        dispatch({ type: 'CHANGE_NUMBEROFHASHTAGS', numberofhashtags: newValue });
    };

    const navigate = useNavigate();

    const [paramsDisabled, setParamsDisabled] = React.useState(false);

    useEffect(() => {
        switch (preset) {
            case "short":
                setMaxLength(150);
                if(length >= 150) {
                    dispatch({ type: 'CHANGE_LENGTH', length: 150 });
                }
                break;
            case "long":
                setMaxLength(500);
                break;
            default:
                setMaxLength(150);
                break;
        }

    }, [preset]);

    return (
        <>
            {plan !== "Custom Plan" ? (
                <Box>
                    <Box justifyContent="center" display="flex" pt={4}>
                        <FormControl sx={{ pb: 6, width: 285 }}>
                            <InputLabel>Pre-Set*</InputLabel>
                            <Select
                                value={preset}
                                onChange={handlePresetChange}
                                label="Pre-Set"
                            >
                                <MenuItem value="short">Short caption (Instagram, Facebook, TikTok)</MenuItem>
                                <MenuItem value="long">Long caption (LinkedIn)</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box justifyContent="center" display="flex">
                        <FormControl sx={{ pb: 4, minWidth: 285 }}>
                            <InputLabel>Caption Language*</InputLabel>
                            <Select
                                value={language}
                                onChange={handleLanguageChange}
                                label="Caption Language *"
                            >
                                <MenuItem value="us">🇺🇸 (American) English</MenuItem>
                                <MenuItem value="uk">🇬🇧 (British) English</MenuItem>
                                <MenuItem value="de">🇩🇪 German</MenuItem>
                                <MenuItem value="fr">🇫🇷 French</MenuItem>
                                <MenuItem value="es">🇪🇸 Spanish</MenuItem>
                                <MenuItem value="ar">🇦🇪 Arabic</MenuItem>
                                <MenuItem value="ch">🇨🇳 Chinese</MenuItem>
                                <MenuItem value="ko">🇰🇷 Korean</MenuItem>
                                <MenuItem value="ja">🇯🇵 Japanese</MenuItem>
                                <MenuItem value="ru">🇷🇺 Russian</MenuItem>
                                <MenuItem value="pr">🇵🇹 Portugese</MenuItem>
                                <MenuItem value="it">🇮🇹 Italian</MenuItem>
                                <MenuItem value="pl">🇵🇱 Polish</MenuItem>
                                <MenuItem value="al">🇦🇱 Albanian</MenuItem>
                                <MenuItem value="bu">🇧🇬 Bulgarian</MenuItem>
                                <MenuItem value="cr">🇭🇷 Croatian</MenuItem>
                                <MenuItem value="cz">🇨🇿 Czech</MenuItem>
                                <MenuItem value="da">🇩🇰 Danish</MenuItem>
                                <MenuItem value="du">🇳🇱 Dutch</MenuItem>
                                <MenuItem value="fi">🇫🇮 Finnish</MenuItem>
                                <MenuItem value="sw">🇸🇪 Swedish</MenuItem>
                                <MenuItem value="gr">🇬🇷 Greek</MenuItem>
                                <MenuItem value="hu">🇭🇺 Hungarian</MenuItem>
                                <MenuItem value="ro">🇷🇴 Romanian</MenuItem>
                                <MenuItem value="tu">🇹🇷 Turkish</MenuItem>
                                <MenuItem value="hi">🇮🇳 Hindi</MenuItem>
                                <MenuItem value="be">🇮🇳 Bengali</MenuItem>
                                <MenuItem value="te">🇮🇳 Telugu</MenuItem>
                                <MenuItem value="ma">🇮🇳 Marathi</MenuItem>
                                <MenuItem value="ta">🇮🇳 Tamil</MenuItem>
                                <MenuItem value="ur">🇮🇳 Urdu</MenuItem>
                                <MenuItem value="ka">🇮🇳 Kannada</MenuItem>
                                <MenuItem value="od">🇮🇳 Odia</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>


                    <Divider sx={{ mb: 4 }} />

                    <FormControl sx={{ pb: 4, minWidth: 285 }}>
                        <InputLabel>Brand voice*</InputLabel>
                        <Select
                            value={brandvoice}
                            onChange={handleBrandvoiceChange}
                            label="Brand voice *"
                        >
                            <MenuItem value="use" disabled={(brandvoiceExist ? false : true)}>✅ use brandvoice {(brandvoiceExist ? "" : "(set-up first)")}</MenuItem>
                            <MenuItem value="none">❌ no brandvoice</MenuItem>
                        </Select>
                        <Button onClick={() => navigate('/settings')} variant='text' size='small' sx={{ width: "150px", alignItems: "center" }}>📝 edit brand voice</Button>
                    </FormControl>

                    <Stack direction="column" spacing={0} justifyContent={'center'} alignItems={"center"} >
                        {brandvoice === "none" ?
                            <>
                                <ParameterField options={['advertisment', 'Behind the scenes', 'Tutorial', 'product showcase', 'giveaway', 'contest', 'inspirational quote', 'meme', 'storytelling']} value={theme} handleParamChange={handleThemeChange} label={"Theme"} title={"Help the AI understand what your post is about, if it is not recognizable in your photo/video."} />
                                <ParameterField options={['[company name] office', 'New York', 'at home', 'in the Gym', 'in our factory', 'showroom', 'trade show in [city]', 'Pop-up store in [location]']} value={setting} handleParamChange={handleSettingChange} label={"Setting/location"} title={"Choose the location/setting of the photo, if you want it to be mentioned in the text."} />
                                <ParameterField options={['natural', 'casual', 'professional', 'humorous', 'friendly', 'inspirational', 'educational', 'storytelling', 'sales-oriented', 'customer-Centric', 'Elon Musk', 'Apple', 'Nike', 'Gary Vaynerchuk', 'Sephora', 'Patagonia', 'Oprah Winfrey']} value={mood} handleParamChange={handleMoodChange} label={"Tone"} title={"The wording and formulation of the caption. Make sure it matches to your brand voice."} />
                            </>
                            :
                            <></>
                        }
                        <ParameterField options={['Giveaway with [brand]', 'Easter XX% discount', '[topic] awareness month', 'Summer special', 'Collaboration with [@Person]', 'holiday in Greece']} value={custom} handleParamChange={handleCustomChange} label={"Custom Prompt"} title={"Whatever information is not covered by the other parameters and not obvious from the photo/video."} />
                    </Stack>

                    <Divider />

                    <Stack direction="column" spacing={2} pb={4} pt={4} justifyContent={'center'} alignItems={"center"}>

                        <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Emojis*"} value={emojis} handleCheckboxChange={handleEmojisChange} />
                        <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Hashtags*"} value={hashtags} handleCheckboxChange={handleHashtagsChange} />

                        {hashtags ?
                            <ParameterSlider minValue={0} maxValue={maxHashtagLength} paramsDisabled={paramsDisabled} label={"Number of Hashtags*"} value={numberofhashtags} valueSteps={1} labelValue={numberofhashtags} handleSlideChange={handleHashtagsLengthChange} title={"How many AI generated hashtags you want after your caption. If you have set custom hashtags they are added after the number of hashtags you set here."} />
                            :
                            <></>
                        }

                        <ParameterSlider minValue={2} maxValue={maxLength} paramsDisabled={paramsDisabled} label={"Number of words*"} value={length} valueSteps={1} labelValue={length} handleSlideChange={handleLengthChange} title={"How long your caption should be (not including hashtags)."} />

                        <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Call-to-Action*"} value={cta} handleCheckboxChange={handleCtaChange} />
                        {cta ?
                            <ParameterField options={['Engagement (like, comment, share, tag...)', 'Click-through (learn more, read more, sign up, download...)', 'Purchase (shop now, buy now, order today...)', 'Event promotion (join us, register, RSVP...)', 'content sharing (watch, listen...)', 'Follow and subscribe (follow us, subscribe...)']} value={ctaType} handleParamChange={handleCtaTypeChange} label={"CTA type"} title={"What type of Call-to-Action do you want?"} />
                            :
                            <></>
                        }
                        <Button onClick={() => navigate("/settings#generalsettings")} variant='text' size='large' sx={{ width: "210px", alignItems: "center" }}>⚙️ save your settings</Button>
                    </Stack>

                </Box >
            ) :
                (
                    <>
                        <Stack direction="column" spacing={0} justifyContent={'center'} alignItems={"center"} pb={4}>
                            <ParameterField options={['Giveaway with [brand]', 'Oster XX% Rabatt', '[topic] awareness month', 'Frühlings special', 'Collaboration with [@Person]']} value={custom} handleParamChange={handleCustomChange} label={"Select from options or type..."} title={"Add additional important information here."} />
                            <ParameterSlider minValue={2} maxValue={maxLength} paramsDisabled={paramsDisabled} label={"Caption length"} value={length} valueSteps={1} labelValue={length} handleSlideChange={handleLengthChange} title={"How many words your text should be (not including hashtags). A value of at least 25 words is recommended for social media posts."} />
                            <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Hashtags"} value={hashtags} handleCheckboxChange={handleHashtagsChange} />
                            {hashtags && (<ParameterSlider minValue={1} maxValue={30} paramsDisabled={paramsDisabled} label={"Number of Hashtags"} value={numberofhashtags} valueSteps={1} labelValue={numberofhashtags} handleSlideChange={handleHashtagsLengthChange} title={"How many hashtags do you want? For Reels 3-5 is recommended. For static Posts 20 - 30 is recommended."} />)}
                        </Stack>

                    </>
                )}
        </>
    );
};

export default CaptionOptions;

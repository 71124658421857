import { ThemeProvider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ImageContext, ImageDispatchContext, ImageProvider } from './context/ImageContext';
import { Header } from './components/header';
import { BodyBox } from './components/bodybox';
import { CustomTheme } from './theme/theme';
import { Footer } from './components/footer';
import AboutUsPage from './components/about-us';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Outlet, Route, Routes } from "react-router-dom";
import PrivacyPolicyPage from './components/privacy-policy';
import AGBPage from './components/agb';
import ImprintPage from './components/imprint';
import { CookiesProvider } from "react-cookie";
import Tool from './components/tool';
import "@aws-amplify/ui-react/styles.css";
import {
  Authenticator, Theme
} from "@aws-amplify/ui-react";
import {
  ThemeProvider as AmplifyThemeProvider,
  useTheme as useAmplifyTheme,
  Theme as AmplifyTheme,
} from "@aws-amplify/ui-react";
import { SuccessPage } from './components/stripe/success';
import { CancelPage } from './components/stripe/cancel';
import AccountPage from './components/account';
import { useContext, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import axios from 'axios';
import { TopNav } from './components/header/components/topnav';
import { useCookies } from "react-cookie";
import { Help } from './components/help';
import AppSumo from './components/tool/components/pricingPlans/appSumo';
import { SuccessPageAppSumo } from './components/stripe/successAppSumo';
import SettingsPage from './components/account/settingsPage';
import { NotificationBar } from './components/header/components/notificationBar';

const App = () => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={CustomTheme.getTheme()}>
        <ImageProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AuthWrapper />} >
                <Route index element={<Tool />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path='/settings' element={<SettingsPage />} />
                <Route path="/help" element={<Help />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/terms-and-conditions" element={<AGBPage />} />
                <Route path="/imprint" element={<ImprintPage />} />
                <Route path="/success_personal" element={<SuccessPage />} />
                <Route path="/success_business" element={<SuccessPage />} />

                <Route path="/success_basic" element={<SuccessPage />} />
                <Route path="/success_plus" element={<SuccessPage />} />
                <Route path="/success_elite" element={<SuccessPage />} />
                <Route path="/cancel" element={<CancelPage />} />

                <Route path='/app-sumo' element={<AppSumo />} />
                <Route path='/success_appsumo' element={<SuccessPageAppSumo />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ImageProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}

const AuthWrapper = () => {
  const amplifyTheme: AmplifyTheme = {
    name: 'Auth Theme imagetocaption',
    tokens: {
      colors: {
        brand: {
          primary: {
            '10': '#35D0BA',
            '80': '#5EDAC8',
            '90': '#28AA98',
            '100': '#2A2A2A',
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: '#111111',
              },
            },
            _hover: {
              color: {
                value: '#28AA98',
              },
            },
            _active: {
              color: {
                value: '#111111',
              },
            },
          },
        },
      },
    },
  };
  const location = useLocation();

  return (
    <> 
      <TopNav />
      <AmplifyThemeProvider theme={amplifyTheme}>
        <Authenticator
          signUpAttributes={[
            'email',
            'name',
          ]}
          socialProviders={['google']}
        >
          {({ signOut }) => {
            return (
              <Layout signOut={signOut} />
            );
          }}
        </Authenticator>
      </AmplifyThemeProvider>
      <Footer />
    </>
  );
}

const Layout = ({ signOut }) => {
  const dispatch = useContext(ImageDispatchContext);
  const { checkingSubscriptionStatus, subscriptionStatus } = useContext(ImageContext);

  const [cookies, setCookie, removeCookie] = useCookies(['referalID']);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //prod:
  const serverUrl = "https://80r61llt1b.execute-api.eu-central-1.amazonaws.com/prod";
  //test:
  // const serverUrl = "http://localhost:3001";

  useEffect(() => {
    fetchUsername().then((res) => {
      dispatch({ type: 'CHANGE_USERNAME', username: res });
      fetchCustomerId(res).then((res) => {
        if (res !== undefined) {
          getSubscriptionStatus(res);
        }
      }).catch((error) => {
        console.log(error);
      });
    }).catch((error) => {
      console.log(error);
    });

    // Check if the global variable 'rewardful' is available
    if (window['rewardful']) {
      window['rewardful']('ready', function () {
        if (window['Rewardful'] && window['Rewardful'].referral) {
          setCookie('referalID', window['Rewardful'].referral);
        } else {
          console.log('No referral present.');
        }
      });
    } else {
      console.warn('rewardful is not defined. Make sure the script is loaded properly.');
    }
    getCountry();
  }, []);


  const fetchUsername = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user.username;
    } catch (error) {
      console.error('Error fetching username:', error);
    }
  };

  const fetchCustomerId = async (username) => {
    try {
      const response = await axios.get(serverUrl + "/payment/customerid/" + username);
      if (response.status === 200) {
        return response.data.customerid;
      } else {
        dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: "free plan" });
        throw new Error('Failed to fetch customer ID');
      }
    } catch (error) {
      console.error('Error fetching customer ID:', error);
      dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: "free plan" });
      dispatch({ type: 'CHANGE_CHECKING_SUBSCRIPTION_STATUS', checkingSubscriptionStatus: false });
    }
  };

  const getSubscriptionStatus = async (customerid) => {
    try {
      await axios.post(serverUrl + '/payment/subscriptions', { customerId: customerid }).then((res) => {
        if (res.status === 404 || res.data.subscriptions.data.length === 0) {
          dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: "not subscribed" });
          dispatch({ type: 'CHANGE_PERIOD_START', periodStart: "" });
          dispatch({ type: 'CHANGE_PERIOD_END', periodEnd: "" });
        } else {
          const subscriptions = res.data.subscriptions.data[0];
          const productName = res.data.productName;
          let subscriptionMail = "";
          if (subscriptions.default_payment_method) {
            subscriptionMail = subscriptions.default_payment_method.billing_details.email;
          }

          switch (subscriptions.status) {
            case "active":
              dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: subscriptions.status });
              dispatch({ type: 'CHANGE_PERIOD_START', periodStart: convertTimestampToDate(subscriptions.current_period_start) });
              dispatch({ type: 'CHANGE_PERIOD_END', periodEnd: convertTimestampToDate(subscriptions.current_period_end) });
              dispatch({ type: 'CHANGE_PLAN', plan: productName });
              dispatch({ type: 'CHANGE_STRIPE_EMAIL', stripeEmail: subscriptionMail });
              break;
            case "trialing":
              dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: subscriptions.status });
              dispatch({ type: 'CHANGE_PERIOD_START', periodStart: convertTimestampToDate(subscriptions.current_period_start) });
              dispatch({ type: 'CHANGE_PERIOD_END', periodEnd: convertTimestampToDate(subscriptions.current_period_end) });
              dispatch({ type: 'CHANGE_PLAN', plan: productName });
              dispatch({ type: 'CHANGE_STRIPE_EMAIL', stripeEmail: subscriptionMail });
              break;
            case "canceled":
              dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: subscriptions.status });
              dispatch({ type: 'CHANGE_PERIOD_START', periodStart: convertTimestampToDate(subscriptions.current_period_start) });
              dispatch({ type: 'CHANGE_PERIOD_END', periodEnd: convertTimestampToDate(subscriptions.current_period_end) });
              dispatch({ type: 'CHANGE_PLAN', plan: productName });
              dispatch({ type: 'CHANGE_STRIPE_EMAIL', stripeEmail: subscriptionMail });
              break;
            default:
              dispatch({ type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: "not subscribed" });
              dispatch({ type: 'CHANGE_PERIOD_END', periodEnd: "" });
              dispatch({ type: 'CHANGE_PERIOD_DAYS_LEFT', periodDaysLeft: 0 });
              dispatch({ type: 'CHANGE_PLAN', plan: "" });
              break;
          }

          // initializeCredits(productName);
          //moved initialization to stripe webhook
        }
        dispatch({ type: 'CHANGE_CHECKING_SUBSCRIPTION_STATUS', checkingSubscriptionStatus: false });
      }).catch((error) => {
        console.log(error);
      });

    } catch (error) {
      console.log(error);
    }
  };


  const getCountry = async () => {
    const apiName = 'imagetocaptionapipro';
    const path = '/country';
    const myInit = {
      body: {
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };


    API.post(apiName, path, myInit).then((response) => {
      dispatch({ type: 'CHANGE_ORIGIN_COUNTRY', originCountry: response });
    }).catch((error) => {
      console.log(error);
      dispatch({ type: 'CHANGE_ORIGIN_COUNTRY', originCountry: "other" });
    });
  }

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    dispatch({ type: 'CHANGE_PERIOD_DAYS_LEFT', periodDaysLeft: Math.floor((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)) });
    return date.toLocaleDateString();
  }

  return (
    <>
      <Header signOut={signOut} />
      <BodyBox>
        {checkingSubscriptionStatus === true ? (
          <>
            <Typography>Checking Subscription status...</Typography>
          </>
        ) : (
          <>
            {subscriptionStatus === "active" ? (
              <>
                <Outlet />
              </>
            )
              :
              (
                <Outlet />
              )}
          </>
        )}
      </BodyBox>
      {/* <Box width={'100%'} alignSelf={'center'} textAlign={'center'} pb={2} display={isMobile ? 'inherit' : 'none'}>
        <Button onClick={signOut} color="secondary" variant='outlined' size={'small'}>Sign Out</Button>
      </Box> */}
    </>
  )
}

export default App;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure({
  ...config,

  API: {
    endpoints: [
      {
        name: "paymentapi",
        endpoint: "https://80r61llt1b.execute-api.eu-central-1.amazonaws.com/prod"
      }
    ]
  }

});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
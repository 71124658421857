import React, { useEffect } from 'react';
import { Box, Button, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubNav = ({ signOut }) => {
    const [currentTab, setCurrentTab] = React.useState<number | boolean>(0);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        switch (window.location.pathname) {
            case '/':
                setCurrentTab(0)
                break;
            case '/account':
                setCurrentTab(1)
                break;
            case '/settings':
                setCurrentTab(2);
                break;
            default:
                setCurrentTab(false);
        }
    }, [window.location.pathname])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        switch (newValue) {
            case 0:
                navigate('/');
                break;
            case 1:
                navigate('/account')
                break;
            case 2:
                navigate('/settings')
                break;
            default:
                navigate('/');
        }
        setCurrentTab(newValue);
    }

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: 'white',
            display: 'flex',
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Box width={{ lg: '35%' }}>
                </Box>
                <Box width={{ lg: '30%' }}>
                    <Tabs centered value={currentTab} onChange={handleChange}>
                        <Tab sx={{
                            color: '#9C26B0', fontWeight: 'bold'
                        }} label={"Captions"} />
                        <Tab label={"Account"} />
                        <Tab label={"Settings"} />
                    </Tabs>
                </Box>
                <Box width={{ lg: '35%' }} textAlign={"end"} pr={isMobile ? 0 : 2} alignSelf={'center'} display={isMobile ? 'none' : 'flow-root'}>
                    <Button onClick={signOut} color="secondary" variant='outlined' size={isMobile ? 'small' : 'medium'}>Sign Out</Button>
                </Box>
            </Box>
        </Box >
    );
}

export default SubNav;
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Faq = () => {

    return (
        <Box mt={4}>
            <Typography variant="h5" pb={4} pt={2}>Frequently Asked Questions</Typography>
            <Box>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6" component="h3" textAlign={"left"}>How does imagetocaption.ai work?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography textAlign={"left"}>
                            It's incredibly easy to use image to caption AI! Start by uploading your chosen image or video, and then select the target platform - be it Instagram, an online shop, or Facebook. This flexibility makes it an ideal image caption online tool for any platform. Next, choose the language for your caption, ensuring our caption generator aligns perfectly with your audience.
                            <br /><br />
                            Then, dive into customizing your caption to fit your specific needs. This includes setting the theme, pinpointing the location of the photo, defining your target audience, selecting the tone, form of address, and adding any additional custom information. These details help our caption AI craft a caption that resonates with your audience.
                            <br /><br />
                            You also have the option to include hashtags, emojis, and a call-to-action, making it a comprehensive caption maker for all your needs. Plus, you can adjust the creativity level and length of the output, ensuring our caption generator from photo delivers exactly what you need.
                            <br /><br />
                            Finally, hit 'Create Caption' and in just a few seconds, a fitting caption, tailored for platforms like Instagram or Facebook, will be generated for you. If it's not quite right, you can easily tweak the parameters and try again. This level of customization and ease-of-use makes imagetocaption your go-to solution for all your picture caption needs.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6" component="h3" textAlign={"left"}>Can I upload videos?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography textAlign={"left"}>
                            Yes! You also generate captions for your videos. The video needs to be .mp4 file.
                            <br /><br />
                            Personal plan: you can upload videos up to 500MB and 1min length.
                            <br />
                            Business plan: you can upload videos up to 2GB and 3min length.
                            <br /><br />
                            If you need to upload bigger or longer videos please reach out to us at info@imagetocaption.ai.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6" component="h3" textAlign={"left"}>How can I make captions for my Instagram Reel or TikTok?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography textAlign={"left"}>
                            imagetocaption.ai is perfect for creating captions for your Instagram Reel or TikTok.
                            <br /><br />
                            Steps to create a caption directly from your Instagram or TikTok:
                            <br /><br />
                            1. Create a draft of your Reel or TikTok video in the Instagram App or TikTok App.
                            <br />
                            2. Download the draft video to your phone.
                            <br />
                            3. Upload the video from your photo library to imagetocaption.ai.
                            <br />
                            4. Select Instagram/TikTok as target plattform and add optionaly add parameters to individualize your result.
                            <br />
                            5. Click on 'Generate caption'.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6" component="h3" textAlign={"left"}>What if the caption doesn't fit?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography textAlign={"left"}>
                            You can regenerate the caption as often as you want. Every time a slightly different caption is generated. You can also influence the creativity of the caption.
                            Therefore chances are basically zero that you won’t get a caption which fits to your preferences.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6" component="h3" textAlign={"left"}>How many credits does a caption cost?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography textAlign={"left"}>
                            Images: 1 credit / caption
                            <br /><br />
                            Videos: Each video has a minimum cost of 3 credits. If the video is longer than 30 seconds you will be charged 1 additional credit for every additional 30 seconds. Regeneration of a caption (e.g. with different parameters) for a video only costs 1 credit.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h6" component="h3" textAlign={"left"}>Which languages are supported?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography textAlign={"left"}>
                            Currently we support English, German, French, Spanish, Arabic, Chinese, Korean, Japanese, Russian, Portugese, Polish, Albanian, Bulgarian, Croatian, Czech, Danish, Dutch, Finnish, Swedish, Greek, Hungarian, Romanian, Turkish. Also we support the following Indian languages: Hindi, Bengali, Telugu, Marathi, Tamil. Urdu, Kannada, Odia. If you need another language please reach out to us at info@imagetocaption.ai
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box >
    )
}
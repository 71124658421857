import { Button } from "@mui/material";
import axios from "axios";
import { useContext } from "react";
import { ImageContext } from "../../context/ImageContext";

export const PurchaseButtonCredits50 = () => {
    const { username } = useContext(ImageContext);

    const serverUrl = "https://80r61llt1b.execute-api.eu-central-1.amazonaws.com/prod";

    const handleCheckout = async (event: any) => {

        event.preventDefault();
        try {
            const response = await axios.post(serverUrl + '/payment/create-checkout-session-credits', {
                username: username,
                plan: "credits",
                credits: 50
            });

            console.log(response.data);
            window.location.href = response.data.url;
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <form onSubmit={handleCheckout} method="POST">
                <Button variant="contained" color="secondary" type="submit">
                    Top up 50 credits
                </Button>
            </form>
        </>
    )
}
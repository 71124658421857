import { useEffect } from 'react';
import { Help } from "../help";
import { Faq } from "../tool/components/pricingPlans/faq";
import Settings from "./settings";

const SettingsPage = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Settings />
            <Help/>
            <Faq/>
        </>
    )
}


export default SettingsPage;
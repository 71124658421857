import { useContext, useEffect, useState } from "react";
import { Box, Skeleton, LinearProgress, Stack, Typography, ImageList, ImageListItem, useMediaQuery, useTheme } from "@mui/material";
import { ImageContext } from "../../../context/ImageContext";

const ImagePreview = () => {
    const { imageUrl, imageUploading, imageUploaded } = useContext(ImageContext);
    const [url, setUrl] = useState([]);
    const [multipleImages, setMultipleImages] = useState(false);

    useEffect(() => {
        if (imageUrl.includes(';')) {
            setUrl(imageUrl.split(';').filter(url => url.trim() !== ''))
            setMultipleImages(true);
        } else if (imageUrl !== "") {
            setUrl([imageUrl]);
            setMultipleImages(false);
        }

    }, [imageUrl]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (imageUploading === true) {
        return (
            <Box>
                <Stack direction="column" spacing={1}>
                    <Skeleton sx={{ height: 250, width: 400, alignSelf: "center" }} animation={"wave"} />
                    <Typography variant="caption" align="center">Uploading...</Typography>
                    <Box width={'80%'} alignSelf={'center'}>
                        <LinearProgress />
                    </Box>
                </Stack>
            </Box>
        );
    } else if (imageUploading === false && imageUploaded === true) {
        return (
            <>
                {imageUrl.endsWith(".mp4") ? (
                    <Box component="video" sx={{ height: 250, alignSelf: "center" }} controls>
                        <source src={imageUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </Box>
                ) : (
                    <>
                        {multipleImages ? (
                            <ImageList variant="masonry" cols={isMobile ? 2 : 3} gap={8} rowHeight={isMobile ? 164 : 200 }>
                                {url.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <img
                                            src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            alt={`image-${index}`}
                                            loading="lazy"
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        ) : <Box component="img" sx={{ height: 250, alignSelf: "center" }} alt="uploaded image" src={url[0]} />}
                    </>
                )
                }
            </>

        )
    } else {
        return (
            <Box />
        )
    }
};

export default ImagePreview;
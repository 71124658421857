import Box from '@mui/material/Box';
import { Divider } from '@mui/material';
import SubNav from './components/subnav';

export function Header({ signOut }) {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Divider />
      <SubNav signOut={signOut} />
    </Box>
  );
}
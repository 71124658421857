import { Box, Button, CircularProgress, FormControl, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ImageContext } from "../../../../context/ImageContext";
import { Auth } from 'aws-amplify';

const AppSumo = () => {
    const { username, plan } = useContext(ImageContext);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [codeOne, setCodeOne] = useState("");
    const [codeTwo, setCodeTwo] = useState("");
    const [codeThree, setCodeThree] = useState("");
    const [loading, setLoading] = useState(false);
    const [codes, setCodes] = useState([]);
    const serverUrl = "https://80r61llt1b.execute-api.eu-central-1.amazonaws.com/prod";
    const [color1, setColor1] = useState("primary");
    const [color2, setColor2] = useState("primary");
    const [color3, setColor3] = useState("primary");
    const [disabled1, setDisabled1] = useState(false);
    const [disabled2, setDisabled2] = useState(true);
    const [disabled3, setDisabled3] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        let tempCodes = [];

        if (codeOne !== "" && codeOne !== null) {
            tempCodes = [...tempCodes, codeOne];
            setDisabled2(false);
        }

        if (codeTwo !== "" && codeTwo !== null) {
            tempCodes = [...tempCodes, codeTwo];
            setDisabled3(false);
        }

        if (codeThree !== "" && codeThree !== null) {
            tempCodes = [...tempCodes, codeThree];
        }

        setCodes(tempCodes);

        if (tempCodes.length > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }

        // Check for duplicates
        const uniqueCodes = new Set(tempCodes);
        if (uniqueCodes.size < tempCodes.length) {
            setButtonDisabled(true);
        } else {
            if (tempCodes.length > 0) {
                setButtonDisabled(false);
            } else {
                setButtonDisabled(true);
            }
        }

    }, [codeOne, codeTwo, codeThree]);


    const handleCheckout = async (event) => {
        event.preventDefault();
        setLoading(true);
        let valid = [];

        for (let i = 0; i < codes.length; i++) {
            const code = codes[i];

            try {
                const response = await axios.post(serverUrl + '/payment/check-code', {
                    code: code,
                });
                console.log(response.data);
                if (i === 0) {
                    setColor1("success");
                } else if (i === 1) {
                    setColor2("success");
                } else {
                    setColor3("success");
                }
                if (response.status === 200) {
                    if (response.data.used !== true) {
                        valid[i] = true;
                    } else {
                        valid[i] = false;
                        if (i === 0) {
                            setColor1("warning");
                        } else if (i === 1) {
                            setColor2("warning");
                        } else {
                            setColor3("warning");
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                valid[i] = false;
                if (i === 0) {
                    setColor1("warning");
                } else if (i === 1) {
                    setColor2("warning");
                } else {
                    setColor3("warning");
                }
            } finally {
                setLoading(false);
            }
        }
        if (valid.every(v => v === true)) {
            console.log("valid");
            const numberOfCodes = valid.filter(v => v === true).length;
            let sumoplan = "sumo_basic";
            switch (numberOfCodes) {
                case 1:
                    if (plan === "Basic Plan") {
                        sumoplan = "sumo_plus";
                    } else if (plan === "Plus Plan") {
                        sumoplan = "sumo_elite";
                    } else {
                        sumoplan = "sumo_basic";
                    }
                    break;
                case 2:
                    if (plan === "Basic Plan") {
                        sumoplan = "sumo_elite";
                    } else {
                        sumoplan = "sumo_plus";
                    }
                    break;
                case 3:
                    sumoplan = "sumo_elite";
                    break;

                default:
                    break;
            }
            try {
                const user = await Auth.currentAuthenticatedUser();
                const email = user.attributes.email;

                const response = await axios.post(serverUrl + '/payment/create-checkout-session-sumo', {
                    username: username,
                    plan: sumoplan,
                    period: "monthly",
                    email: email,
                });

                if (response.status === 200) {
                    for (let i = 0; i < codes.length; i++) {
                        const code = codes[i];
                        console.log(code);
                        axios.post(serverUrl + '/payment/invalidate-code', { code: code })
                    }
                    window.location.href = '/success_appsumo';
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        }

    };

    return (
        <Box>
            <Typography variant="h4" align="center">Reedem your AppSumo codes</Typography>

            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2}>
                <ParameterField value={codeOne} handleParamChange={(value) => { setCodeOne(value); }} label={"First code"} color={color1} disabled={disabled1} />
                <ParameterField value={codeTwo} handleParamChange={(value) => { setCodeTwo(value); }} label={"Second code"} color={color2} disabled={disabled2} />
                <ParameterField value={codeThree} handleParamChange={(value) => { setCodeThree(value); }} label={"Third code"} color={color3} disabled={disabled3} />
            </Box>

            <form onSubmit={handleCheckout} method="POST">
                <Button variant="text" color="secondary" type="submit" disabled={buttonDisabled}>{"Redeem"}</Button>
            </form>

            {loading && <CircularProgress />}

        </Box>
    );
}

const ParameterField = ({ value, handleParamChange, label, color, disabled }) => {
    return (
        <FormControl sx={{ pb: 4, width: 250 }}>
            <TextField
                color={color}
                focused={color === "warning" || color === "success" ? true : false}
                label={label}
                value={value}
                onChange={(event) => handleParamChange(event.target.value)}
                disabled={disabled}
            />
        </FormControl>
    )
}

export default AppSumo;
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { API } from 'aws-amplify';
import { ImageContext } from "../../context/ImageContext";
import { ParameterSlider } from "../tool/components/parameterSlider";

const GeneralSettingsCard = () => {
    const { username, plan } = useContext(ImageContext);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [wordCountName, setWordCountName] = useState(0)

    const [preset, setPreset] = useState("short")

    const [length, setLength] = useState(35);
    const [maxLength, setMaxLength] = useState(100);

    const [hashtags, setHashtags] = useState(12)

    const [defaultHashtags, setDefaultHashtags] = useState("")
    const MAX_HASHTAGS = 20;

    const [language, setLanguage] = useState('us');

    const [alwaysUseEmojis, setAlwaysUseEmojis] = useState(true);
    const [alwaysUseHashtags, setAlwaysUseHashtags] = useState(true);
    const [alwaysUseCTA, setAlwaysUseCTA] = useState(true);

    const [settingsLoading, setSettingsLoading] = useState(false);


    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        switch (preset) {
            case "short":
                setMaxLength(150);
                if (length >= 150) {
                    setLength(150);
                }
                break;
            case "long":
                setMaxLength(500);
                break;
            default:
                setMaxLength(150);
                break;
        }

    }, [preset]);

    const handlePresetChange = (event: { target: { value: any; }; }) => {
        setPreset(event.target.value);
    };

    const handleLengthChange = (event: React.ChangeEvent<{}>, newValue) => {
        setLength(newValue);
    };

    const handleHashtagsChange = (event: React.ChangeEvent<{}>, newValue) => {
        setHashtags(newValue);
    };

    const handleDefaultHashtagsChange = (newText) => {
        const words = newText.trim().split(/\s+/);
        const wordCount = words.length;

        if (wordCount <= MAX_HASHTAGS) {
            setDefaultHashtags(newText);
            setWordCountName(wordCount);
        }
        if (newText == "") {
            setWordCountName(0);
        }
    }

    const handleLanguageChange = (event: { target: { value: any; }; }) => {
        setLanguage(event.target.value);
    };

    const handleAlwaysUseEmojisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlwaysUseEmojis(event.target.checked);
    }

    const handleAlwaysUseHashtagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlwaysUseHashtags(event.target.checked);
    }

    const handleAlwaysUseCTAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlwaysUseCTA(event.target.checked);
    }


    const getSettings = async () => {
        setSettingsLoading(true);
        try {
            const response = await API.get('settingsapi', '/brandvoice/object/' + username, {});
            setPreset(response.preset || 'short')
            setLanguage(response.language || 'us')
            setLength(response.length || 35)
            setHashtags(response.numberofhashtags || 12)
            setDefaultHashtags(response.defaulthashtags || '')
            handleDefaultHashtagsChange(response.defaulthashtags || '')

            setAlwaysUseEmojis(response.emojis !== undefined ? response.emojis : true);
            setAlwaysUseHashtags(response.hashtags !== undefined ? response.hashtags : true);
            setAlwaysUseCTA(response.cta !== undefined ? response.cta : true);
        } catch (error) {
            console.log(error);
        }
        setSettingsLoading(false);
    }

    const updateSettings = async () => {
        setSaveDisabled(true);
        try {
            const response = await API.put('settingsapi', '/brandvoice', {
                body: {
                    username: username,
                    language: language,
                    emojis: alwaysUseEmojis,
                    hashtags: alwaysUseHashtags,
                    cta: alwaysUseCTA,
                    length: length,
                    numberofhashtags: hashtags,
                    defaulthashtags: defaultHashtags,
                    preset: preset
                }
            });
        } catch (error) {
            console.log(error);
        }
        setSaveDisabled(false);
    }


    return (
        <Card>
            <CardContent>
                <Typography variant="h5">General Settings</Typography>
                <Box justifyContent="center" display="flex" pt={4}>
                    <FormControl sx={{ pb: 2, width: 285 }}>
                        <InputLabel>Pre-Set*</InputLabel>
                        <Select
                            value={preset}
                            onChange={handlePresetChange}
                            label="Pre-Set"
                        >
                            <MenuItem value="short">Short caption (Instagram, Facebook, TikTok)</MenuItem>
                            <MenuItem value="long">Long caption (LinkedIn)</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                    <ParameterSlider minValue={2} maxValue={maxLength} paramsDisabled={false} label={"Default caption length (words)"} value={length} valueSteps={1} labelValue={length} handleSlideChange={handleLengthChange} title={"How long your default caption length should be."} />
                </Box>

                <Box justifyContent="center" display="flex" mt={2}>
                    <FormControl sx={{ pb: 3, minWidth: 285 }}>
                        <InputLabel>Default Caption Language</InputLabel>
                        <Select
                            value={language}
                            onChange={handleLanguageChange}
                            label="Caption Language *"
                        >
                            <MenuItem value="us">🇺🇸 (American) English</MenuItem>
                            <MenuItem value="uk">🇬🇧 (British) English</MenuItem>
                            <MenuItem value="de">🇩🇪 German</MenuItem>
                            <MenuItem value="fr">🇫🇷 French</MenuItem>
                            <MenuItem value="es">🇪🇸 Spanish</MenuItem>
                            <MenuItem value="ar">🇦🇪 Arabic</MenuItem>
                            <MenuItem value="ch">🇨🇳 Chinese</MenuItem>
                            <MenuItem value="ko">🇰🇷 Korean</MenuItem>
                            <MenuItem value="ja">🇯🇵 Japanese</MenuItem>
                            <MenuItem value="ru">🇷🇺 Russian</MenuItem>
                            <MenuItem value="pr">🇵🇹 Portugese</MenuItem>
                            <MenuItem value="it">🇮🇹 Italian</MenuItem>
                            <MenuItem value="pl">🇵🇱 Polish</MenuItem>
                            <MenuItem value="al">🇦🇱 Albanian</MenuItem>
                            <MenuItem value="bu">🇧🇬 Bulgarian</MenuItem>
                            <MenuItem value="cr">🇭🇷 Croatian</MenuItem>
                            <MenuItem value="cz">🇨🇿 Czech</MenuItem>
                            <MenuItem value="da">🇩🇰 Danish</MenuItem>
                            <MenuItem value="du">🇳🇱 Dutch</MenuItem>
                            <MenuItem value="fi">🇫🇮 Finnish</MenuItem>
                            <MenuItem value="sw">🇸🇪 Swedish</MenuItem>
                            <MenuItem value="gr">🇬🇷 Greek</MenuItem>
                            <MenuItem value="hu">🇭🇺 Hungarian</MenuItem>
                            <MenuItem value="ro">🇷🇴 Romanian</MenuItem>
                            <MenuItem value="tu">🇹🇷 Turkish</MenuItem>
                            <MenuItem value="hi">🇮🇳 Hindi</MenuItem>
                            <MenuItem value="be">🇮🇳 Bengali</MenuItem>
                            <MenuItem value="te">🇮🇳 Telugu</MenuItem>
                            <MenuItem value="ma">🇮🇳 Marathi</MenuItem>
                            <MenuItem value="ta">🇮🇳 Tamil</MenuItem>
                            <MenuItem value="ur">🇮🇳 Urdu</MenuItem>
                            <MenuItem value="ka">🇮🇳 Kannada</MenuItem>
                            <MenuItem value="od">🇮🇳 Odia</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControlLabel
                        label="Always use Emojis"
                        labelPlacement="end"
                        control={<Checkbox />}
                        checked={alwaysUseEmojis}
                        onChange={handleAlwaysUseEmojisChange}
                        disabled={false}
                    />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControlLabel
                        label="Always use Hashtags"
                        labelPlacement="end"
                        control={<Checkbox />}
                        checked={alwaysUseHashtags}
                        onChange={handleAlwaysUseHashtagsChange}
                        disabled={false}
                    />
                </Box>


                {alwaysUseHashtags ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <ParameterSlider minValue={0} maxValue={30} paramsDisabled={false} label={"Default number of Hashtags"} value={hashtags} valueSteps={1} labelValue={hashtags} handleSlideChange={handleHashtagsChange} title={"How many Hashtags you want to be generated by default by our AI (not including your custom Hashtags from below)."} />
                        </Box>
                        <FormControl sx={{ pb: 4, pt: 4, width: '100%' }}>
                            <TextField
                                label={"Always use those Hashtags"}
                                value={defaultHashtags}
                                onChange={(event) => { handleDefaultHashtagsChange(event.target.value) }}
                                helperText={((MAX_HASHTAGS - wordCountName)) + " Hashtags left"}
                                disabled={settingsLoading}
                            />
                        </FormControl>
                    </>
                    :
                    <>
                    </>
                }

                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControlLabel
                        label="Always use Call-to-Action"
                        labelPlacement="end"
                        control={<Checkbox />}
                        checked={alwaysUseCTA}
                        onChange={handleAlwaysUseCTAChange}
                        disabled={false}
                    />
                </Box>

                <Button disabled={saveDisabled} variant="contained" color="primary" sx={{ width: '100px' }} onClick={() => updateSettings()}>Save</Button>
                {saveDisabled ? <Box alignItems={"center"}><CircularProgress size={20} sx={{ mr: 1 }} />updating settings...</Box> : <></>}

            </CardContent>
        </Card >
    )
}

export default GeneralSettingsCard;
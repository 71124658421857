import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import SubscriptionSwitch from "./subscriptionSwitch";
import BasicPlan from "./basicPlan";
import ElitePlan from "./elitePlan";
import PlusPlan from "./plusPlan";
import { useNavigate } from 'react-router-dom';

const PricingPlans = () => {
  const navigate = useNavigate();

  return (
    <>
      <Stack direction="column" spacing={2}>
        <SubscriptionSwitch />

        <Grid container spacing={1} justifyContent="center">
          <BasicPlan />
          <PlusPlan />
          <ElitePlan />
        </Grid>

        <Box mt={4} textAlign={"left"} display="flex" alignItems="center">
          <Typography variant="h6" component="span">AppSumo: </Typography>
          <Button variant="text" color="secondary" onClick={() => navigate('/app-sumo')}>Reedem your codes</Button>
        </Box>

      </Stack>
    </>
  );

}

export default PricingPlans;
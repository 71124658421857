import { Button } from "@mui/material";
import axios from "axios";
import { useContext } from "react";
import { ImageContext } from "../../context/ImageContext";
import { useCookies } from "react-cookie";

export const PurchaseButtonBasic = () => {
    const { username, paymentPeriod, originCountry } = useContext(ImageContext);
    const [cookies] = useCookies(['referalID']);

    let referalId = 'none';
    (cookies.referalID ? referalId = cookies.referalID : referalId = 'none')

    const serverUrl = "https://80r61llt1b.execute-api.eu-central-1.amazonaws.com/prod";

    const handleCheckout = async (event: any) => {

        event.preventDefault();
        try {
            const response = await axios.post(serverUrl + '/payment/create-checkout-session', {
                username: username,
                plan: "basic",
                period: paymentPeriod,
                referalId: referalId,
                country: originCountry
            });

            console.log(response.data);
            window.location.href = response.data.url;
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <form onSubmit={handleCheckout} method="POST">
                <Button variant="contained" color="secondary" type="submit">
                    <img style={{ display: "none" }} height="1" width="1" alt="" src="https://px.ads.linkedin.com/collect/?pid=5309018&conversionId=14217386&fmt=gif" />
                    Buy plan
                </Button>
            </form>
        </>
    )
}
import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';

const PrivacyPolicyPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Typography variant="h4" component="h1" pb={2}>Privacy Policy</Typography>

      <Typography variant="h5" component="h2" pb={2}>Information Collection</Typography>
      <Typography variant="body1" pb={4}>
        We may collect non-personal data such as usage statistics, device information, and browser type for analytical purposes. By using this website you agree to consent to the collection of such information. We only collect personal information from you if you consent to it via cookie banner. This information is used to provide you with a better user experience and to improve the quality of our services.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>Image Upload and Processing</Typography>
      <Typography variant="body1" pb={4}>
        When you upload images to our platform, they are processed and stored temporarily for generating captions. All uploaded images are automatically deleted from our servers after 24 hours.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>Data Retention and Deletion</Typography>
      <Typography variant="body1" pb={2}>
        We follow strict data retention practices. All images are stored on cloud servers located in Germany. We retain uploaded images for a maximum of 24 hours and then they are permanently deleted from our servers.
      </Typography>

      <Typography variant="body1" pb={2}>
        Please be aware that some data may be transferred to servers located in the United States for processing purposes. We ensure that appropriate data protection measures are in place to safeguard your information in accordance with applicable data protection laws.
      </Typography>

      <Typography variant="body1" pb={2}>
        We may use third-party services (replicate.com, openai.com) to assist in generating image captions. These third-party services may have their own privacy policies and terms of use that you should review. We are not responsible for the privacy practices of any third parties.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>Cookies</Typography>
      <Typography variant="body1" pb={2}>
        We are not placing any cookies on this website.
        However please be aware that we work together with third party providers, including Google, AWS, Meta, HubSpot, LinkedIn and others. Those integrations may place and read cookies on your browser or use web beacons to collect data for analytical and customized advertisment purposes on this website.
        <br />
        You can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info/choices/">www.aboutads.info</a>.
        <br />
        Also, you can manage your cookie settings here:
      </Typography>

      <HubSpotButton />

    </div>
  );
};

function HubSpotButton() {
  const handleClick = () => {
    var _hsp = window._hsp = window._hsp || [];
    _hsp.push(['showBanner']);
  };

  return (
    <Button
      id="hs_show_banner_button"
      variant="contained"
      onClick={handleClick}
    >
      Cookie Settings
    </Button>
  );
}

export default PrivacyPolicyPage;
import Box from '@mui/material/Box';
import { Button, Stack, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function Contact() {

    const handleCopy = () => {
        navigator.clipboard.writeText("info@imagetocaption.ai");
    }

    return (
        <Box>
            <Typography variant="h4" pb={2}>Contact me</Typography>
            <Stack direction="row" spacing={2}>
            <Button onClick={handleCopy}>
                <ContentCopyIcon />
            </Button>
            <Typography pt={0.7}>info@imagetocaption.ai</Typography>
            </Stack>
        </Box>
    );
}
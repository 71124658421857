import { useContext, useEffect } from 'react';
import { ImageContext } from "../../context/ImageContext";
import BrandVoiceCard from './brandVoiceCard';
import GeneralSettingsCard from './generalSettingsCard';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

const Settings = () => {
    const { subscriptionStatus } = useContext(ImageContext);
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 100); // Timeout to ensure DOM is ready
            }
        }
    }, [location])

    return (
        <>
            {subscriptionStatus === "active" || subscriptionStatus === "trialing" || subscriptionStatus === "free plan" ?
                <>
                    <BrandVoiceCard />
                    <Box pb={4}></Box>
                    <div id="generalsettings">
                        <GeneralSettingsCard />
                    </div>
                </>
                :
                <></>}
        </>
    )
}

export default Settings;
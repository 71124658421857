/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "imagetocaptionAPIbears",
            "endpoint": "https://66690dfu34.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "imagetocaptionAPIcoldaid",
            "endpoint": "https://f2u7clqpqa.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "imagetocaptionAPIollo",
            "endpoint": "https://5glczka6aj.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "imagetocaptionZapierAPI",
            "endpoint": "https://ptcjh8m90h.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "imagetocaptionapipro",
            "endpoint": "https://71f1k3cqk7.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "imagetocaptioncreditsapi",
            "endpoint": "https://000nydtxbg.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "paymentapi",
            "endpoint": "https://nq433fsuk2.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "settingsapi",
            "endpoint": "https://i7hko60xx3.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "videotocaptionapi",
            "endpoint": "https://sztpd0v1r5.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        },
        {
            "name": "websummarizer",
            "endpoint": "https://qdxyzi0h5b.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:76b2f8a8-cd01-49a1-bd82-e0838850ac68",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_5ELqYDM3m",
    "aws_user_pools_web_client_id": "64vps66u0k3bb4hf5k1oarea32",
    "oauth": {
        "domain": "o6jycsp5y7bn-staging.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://pro.imagetocaption.ai",
        "redirectSignOut": "https://pro.imagetocaption.ai",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "appsumocodes-staging",
            "region": "eu-central-1"
        },
        {
            "tableName": "captionlogs-staging",
            "region": "eu-central-1"
        },
        {
            "tableName": "captions-staging",
            "region": "eu-central-1"
        },
        {
            "tableName": "settings-staging",
            "region": "eu-central-1"
        },
        {
            "tableName": "users-staging",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;

import { InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Slider, Stack, Tooltip, Typography } from "@mui/material"
import { useState } from "react";

export const ParameterSlider = ({ paramsDisabled, label, value, labelValue, valueSteps, handleSlideChange, title, minValue, maxValue }) => {

    const [tooltipOpen, setTooltipopen] = useState(false);

    const handleTooltipClose = () => {
        setTooltipopen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipopen(true);
    };

    const handleClick = () => {
        setTooltipopen(!tooltipOpen);
    };


    return (
        <Box sx={{ minWidth: 300, width: "70%", alignSelf: "center" }}>
            <Stack direction={"row"} justifyContent={"center"}>
                <Typography pt={2}>
                    {label}: <i>{labelValue}</i>
                </Typography>
                <Tooltip
                    title={title}
                    open={tooltipOpen}
                    onMouseEnter={handleTooltipOpen}
                    onMouseLeave={handleTooltipClose}
                >
                    <Box pt={1.3}>
                        <IconButton onClick={handleClick}>
                            <InfoOutlined />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Stack>
            <Slider
                disabled={paramsDisabled}
                value={value}
                min={minValue}
                max={maxValue}
                step={valueSteps}
                onChange={handleSlideChange}
                valueLabelDisplay="auto"
            />
        </Box>
    )
}
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const MailchimpSignupForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    if (!validateName(name)) {
      setErrorMessage('Please enter a valid name.');
      return;
    }

    // Perform form submission logic
    const formData = new FormData();
    formData.append('EMAIL', email);
    formData.append('MMERGE1', name);

    fetch('https://fotografie-heller.us21.list-manage.com/subscribe/post?u=4814eeba16e3e686afaeb7d13&id=66aea3483e', {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then((response) => {
        // Handle the response
        setSuccessMessage('Form submitted successfully.');
        setEmail('');
        setName('');
        setErrorMessage('');
      })
      .catch((error) => {
        // Handle any error that occurred during form submission
        setErrorMessage('An error occurred while submitting the form. Please try again later.');
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    return name.trim().length > 0;
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
      <div id="mc_embed_signup" style={{ width: '600px' }}>
        <form
          action="https://fotografie-heller.us21.list-manage.com/subscribe/post?u=4814eeba16e3e686afaeb7d13&amp;id=66aea3483e&amp;f_id=001958e1f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
          onSubmit={handleSubmit}
        >
          <div id="mc_embed_signup_scroll">
            <div className="indicates-required">
              <Typography variant="caption">required <span className="asterisk">*</span></Typography>
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                <Typography>E-mail <span className="asterisk">*</span></Typography>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-MMERGE1">
                <Typography>Name <span className="asterisk">*</span></Typography>
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="MMERGE1"
                className="required"
                id="mce-MMERGE1"
                required
              />
              <span id="mce-MMERGE1-HELPERTEXT" className="helper_text"></span>
            </div>
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_4814eeba16e3e686afaeb7d13_66aea3483e" tabIndex={-1} value="" onChange={()=>{}} />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </div>
            </div>
            <Typography variant="caption" style={{ marginTop: '10px', color: 'green' }}>
              {successMessage}
            </Typography>
            <br/>
            <Typography variant="caption" style={{ marginTop: '10px', color: 'red' }}>
              {errorMessage}
            </Typography>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default MailchimpSignupForm;

import { Box, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import logo from './../../images/imagetocaptionai.svg';

export const TopNav = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Box component="img" sx={{ height: isMobile ? 60 : 100, maxWidth: isMobile ? "70%" : "80%", mx: 'auto' }} alt="Logo" src={logo} />
            </Box>
        </Toolbar>
    );
}
import React from 'react';
import { Typography, Link, Box, useMediaQuery, useTheme, Grid } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FlightTakeoff, Code, SentimentSatisfiedAlt, Instagram } from '@mui/icons-material';
import MailchimpSignupForm from './components/MailchimpSignupForm';


const AboutUsPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const style = {
    display: 'flex',
    justifyContent: isMobile ? 'left' : 'center',
    alignItems: 'center',
    marginBottom: isMobile ? 10 : 10,
  }

  return (
    <Box pb={6}>
      <Typography variant="h4" component="h1" pb={2}>About imagetocaption.ai</Typography>
      <Typography variant="body1" pb={4} textAlign={isMobile ? 'justify' : 'center'}>
        It started with a
        <Link href="https://fotografie-heller.de/" target="_blank" rel="noopener noreferrer"> photographer </Link>
        who was tired of writing captions for his wedding photos for Instagram posts. Working as a software engineer alongside being a photographer, he realized the need for a software solution to automate this process. Failing to find such a tool on the internet, he decided to build it himself, giving birth to imagetocaption.ai. Powered by chatGPT. The tool is free to use and will always be.
      </Typography>

      <Grid container spacing={1} alignItems="center" style={{ ...style, flexWrap: 'nowrap' }}>
        <Grid item>
          <FlightTakeoff style={{ color: theme.palette.primary.main }} />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>Established in 2023</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" style={{ ...style, flexWrap: 'nowrap' }}>
        <Grid item>
          <Code style={{ color: theme.palette.primary.main }} />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>Developed with ❤️ in Baden-Württemberg, Germany</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" style={{ ...style, flexWrap: 'nowrap' }}>
        <Grid item>
          <SentimentSatisfiedAlt style={{ color: theme.palette.primary.main }} />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>Already helps thousands of people worldwide every day</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" style={{ ...style, flexWrap: 'nowrap' }}>
        <Grid item>
          <Instagram style={{ color: theme.palette.primary.main }} />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>
            Following and tag me on <Link href="https://www.instagram.com/imagetocaptionai/" target="_blank" rel="noopener noreferrer">Instagram</Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="center" style={{ ...style, flexWrap: 'nowrap' }}>
        <Grid item>
          <LinkedInIcon style={{ color: theme.palette.primary.main }} />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>
            Founder: <Link href="https://www.linkedin.com/in/joshua-heller-1b5326140/" target="_blank" rel="noopener noreferrer">Joshua Heller</Link>
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h5" component="h2" sx={{ mt: 4, pb: 2 }}>Future</Typography>
      <Typography variant="body1" textAlign={isMobile ? 'justify' : 'center'}>
        The imagetocaption.ai tool is continually being improved and features are added continously.<br />
      </Typography>
      <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>
        If you find any bugs or have feature requests, don't hesitate to contact me: <Link href="mailto:info@imagetocaption.ai">info@imagetocaption.ai</Link>
      </Typography>
      <Box mt={4}>
        <Typography variant="body1" textAlign={isMobile ? 'left' : 'center'}>
          Also makre sure to sign up to our newsletter to get informed when new features are released:
        </Typography>
        <MailchimpSignupForm />
      </Box>
    </Box>
  );
};

export default AboutUsPage;

import { Typography } from '@mui/material';
import { useEffect } from 'react';

const AGBPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Typography variant="h4" component="h1" pb={2}>General Terms and Conditions (GTC) of imagetocaption GmbH</Typography>

      <Typography variant="h5" component="h2" pb={2}>Scope</Typography>
      <Typography variant="body1" pb={4}>
        1.1. The following General Terms and Conditions (hereinafter "GTC") apply to all contractual relationships between imagetocaption GmbH (hereinafter "Provider") and its customers (hereinafter "Customer") regarding the use of the services and products offered, including, but not limited to, the Basic, Plus, Elite, Custom, and Enterprise plans, as well as any previous designations of these plans, such as Personal or Business plans.
      </Typography>
      <Typography variant="body1" pb={4}>
        1.2. Deviating or supplementary conditions of the Customer shall not become part of the contract unless their validity is expressly agreed to in writing.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>2. Services of the Provider</Typography>
      <Typography variant="body1" pb={4}>
        2.1. The Provider makes available to the Customer the use of its software, imagetocaption.ai (hereinafter "Software"). The Software enables the automatic generation of texts using artificial intelligence.
      </Typography>
      <Typography variant="body1" pb={4}>
        2.2. The Provider grants the Customer the non-exclusive right to use the Software during the term of the contract.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>3. Conclusion and Duration of the Contract</Typography>
      <Typography variant="body1" pb={4}>
        3.1. The contract between the Provider and the Customer is automatically concluded as soon as the Customer pays for the Software. The purchased plan is then activated for the customer and can be used immediately.
      </Typography>
      <Typography variant="body1" pb={4}>
        3.2. The contract is automatically concluded unless the Customer cancels the subscription within the 3-day trial period. The contract term begins after the trial period expires and automatically extends for the originally agreed period unless the Customer cancels his subscription online at pro.imagetocaption.ai or at least 3 days before the expiration of the current contract term by email at info@imagetocaption.ai.
      </Typography>
      <Typography variant="body1" pb={4}>
        3.3. The Customer has a 14-day right of withdrawal according to statutory requirements after the beginning of the contract.
      </Typography>
      <Typography variant="body1" pb={4}>
        3.4. After this 14-day withdrawal period, a return or withdrawal is excluded. This policy is in place as usage can incur significant costs impacting the sustainability of our offerings. Withdrawal can be made at any time by email to info@imagetocaption.ai.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>4. Compensation and Payment Terms</Typography>
      <Typography variant="body1" pb={4}>
        4.1. The compensation for the use of the Software is based on the prices valid at the time of contract conclusion.
      </Typography>
      <Typography variant="body1" pb={4}>
        4.2. Payment is made according to the agreed payment terms. In case of payment delay, the Provider is entitled to charge default interest at the statutory rate.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>5. Liability</Typography>
      <Typography variant="body1" pb={4}>
        5.1. The Provider is liable exclusively for damages caused by intentional or grossly negligent acts of its legal representatives, senior employees, or agents. Liability for slight negligence is excluded, as far as legally permissible.
      </Typography>
      <Typography variant="body1" pb={4}>
        5.2. Liability for lost profits, indirect damages, or consequential damages is excluded, unless mandatory statutory provisions dictate otherwise.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>6. Data Protection</Typography>
      <Typography variant="body1" pb={4}>
        6.1. The Provider commits to complying with applicable data protection laws. Further information on the processing of personal data can be found in the privacy policy.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>7. Final Provisions</Typography>
      <Typography variant="body1" pb={4}>
        7.1. The Provider reserves the right to amend these GTC at any time. Changes will be communicated to users by publishing a notice on pro.imagetocaption.ai. Such amended terms become effective from the date of their publication on pro.imagetocaption.ai. Users who continue to use the services after the publication of the changes accept the new terms in their entirety.
      </Typography>
      <Typography variant="body1" pb={4}>
        7.2. The laws of the Federal Republic of Germany shall apply.
      </Typography>
      <Typography variant="body1" pb={4}>
        7.3. Should any provisions of these GTC be or become wholly or partly invalid, the validity of the remaining provisions shall not be affected.
      </Typography>

    </div>
  );
};

export default AGBPage;
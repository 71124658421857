import { useContext, useState } from 'react';
import { Chip, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ImageContext, ImageDispatchContext } from '../../../../context/ImageContext';
import { styled } from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 4,
    border: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    justifyContent: 'center',
    '&:not(:first-of-type)': {
      borderRadius: 40,
    },
    '&:first-of-type': {
      borderRadius: 40,
    },
    '&.Mui-selected': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
  },
  backgroundColor: theme.palette.action.selected,
  borderRadius: 40,
  overflow: 'hidden', // Ensure the group acts as a single rounded element
  maxWidth: '330px',
  alignSelf: 'center',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
      border: 'none',
    },
    paddingLeft: 12,
    paddingRight: 12,
    textTransform: 'none',
  },
}));

const SubscriptionSwitch = () => {
  const [alignment, setAlignment] = useState('monthly');
  const { paymentPeriod } = useContext(ImageContext);
  const dispatch = useContext(ImageDispatchContext);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
    dispatch({ type: "CHANGE_PAYMENT_PERIOD", paymentPeriod: paymentPeriod === "yearly" ? "monthly" : "yearly" });

  };

  return (
    <StyledToggleButtonGroup
      size="medium"
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="subscription period"
    >
      <StyledToggleButton value="monthly" aria-label="monthly">
        Monthly
      </StyledToggleButton>
      <StyledToggleButton value="annually" aria-label="annually">
        Annually
        <Chip label="Get 2 months for free!" color="warning" sx={{ ml: 1 }} />
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default SubscriptionSwitch;
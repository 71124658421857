import { Box, Typography } from "@mui/material"
import { useEffect } from "react";

export const SuccessPageAppSumo = () => {
    return (
        <div>
            <Box pb={2} mt={-2}>
                <Typography variant="h2">Welcome to imagetocaption.ai🥳</Typography>
                <Typography variant="h4">Congrats on your AppSumo LTD!</Typography>
            </Box>
            <Box>
                <iframe
                    src="https://v2-embednotion.com/Getting-started-d0fd7f69d0f14cc68ee3191f873fcc47?pvs=74"
                    style={{
                        width: '100%',
                        height: '500px',
                        border: '0px solid #ccc',
                        borderRadius: '10px',
                        padding: 'none'
                    }}
                    title="Knowledgebase"
                />
            </Box>

        </div>
    )
}
import Box from '@mui/material/Box';
import { Card, CardContent, Typography } from '@mui/material';

export function Help() {

    return (
        <Box mt={4}>
            <Card>
                <CardContent>
                    <Typography variant="h5" mb={2}>Help section</Typography>
                    <iframe
                        src="https://v2-embednotion.com/Getting-started-d0fd7f69d0f14cc68ee3191f873fcc47?pvs=74"
                        style={{
                            width: '100%',
                            height: '500px',
                            border: '0px solid #ccc',
                            borderRadius: '10px',
                            padding: 'none'
                        }}
                        title="Knowledgebase"
                    />
                </CardContent>
            </Card>
        </Box>
    );
}